.statusSection {
  max-width: 550px;
  margin-bottom: 16px;
}

.sectionTitle {
  font-size: 14px;
  margin-bottom: 8px;
  color: var(--figma-color-globalSideMenu-innerText);
}

.statusListWrapper {
  border: 1px solid var(--figma-color-line-gray);
  border-radius: 4px;
  overflow: hidden;
}

.statusListHeader,
.statusItem {
  display: grid;
  grid-template-columns: 2fr 1fr 100px;
  gap: 8px;
  padding: 8px 16px;
  align-items: center;
}

.statusListHeader {
  background: var(--figma-color-offWhite);
  font-weight: 600;
}

.statusItem {
  border-top: 1px solid var(--figma-color-line-gray);
}

.status {
  display: flex;
  align-items: center;
  gap: 5px;
}

.waiting {
  color: var(--figma-color-lightBlack);
}
.processing {
  color: var(--figma-color-theme-primary);
}
.success {
  color: var(--figma-color-success-green);
}
.failed {
  color: var(--figma-color-execution-red);
}

.hoverable {
  cursor: pointer;
  text-decoration: underline dotted;
}
