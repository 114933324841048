.dialogContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.dialogDescription {
  display: flex;
  flex-direction: column;
  gap: 8px;
}   